module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5JXXV7K","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6efae077f1f1bf2be77a951fecc0007c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"High Jump","short_name":"highjump","description":"Dê um salto para a excelência. A High Jump oferece soluções do mapeamento à melhoria de processos. São mais de 12 anos de atuação, integrando Processos, Qualidade, Compliance e Tecnologia.","lang":"pt-br","start_url":"/","background_color":"#fff","theme_color":"#29235c","display":"standalone","icon":"src/images/icon.jpeg","icons":[{"src":"src/images/icon.jpeg","sizes":"24x24","type":"image/jpeg"},{"src":"src/images/icon.jpeg","sizes":"1024x1024","type":"image/jpeg"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6efae077f1f1bf2be77a951fecc0007c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
